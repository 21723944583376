<template>
  <div>
    <div class="row gutter-b">
      <div class="col-md-12">
        <div class="card card-custom">
          <div class="card-header card-header-fixed border-0 px-6">
            <div class="row align-items-center col-8 px-0">
                  <div class="col-4 pr-0">
                    <div class="input-icon">
                      <input 
                        type="text" 
                        class="form-control form-control-solid" 
                        :placeholder="$t('mealsystem.placeholder.search')"
                        v-model="search"
                        >
                      <span>
                        <i class="flaticon2-search-1 text-muted"></i>
                      </span>
                    </div>
                  </div>
            
                  <b-button
                    variant="secondary"
                    class="mr-3 ml-3 pr-2" @click.prevent="removeFilters">
                    <i class="fa fa-times icon-md"></i>
                  </b-button>
                  <b-button
                    variant="primary" @click.prevent="searchSuppliers">
                  {{ $t("button.filter") }}   
                  </b-button>

            </div>
            <div class="card-toolbar">
                <span class="btn btn-success" v-b-modal.modal-supplier-edit @click="usetSupplier()">
                  <span class="svg-icon svg-icon-md svg-icon-white">
                    <inline-svg src="/media/svg/icons/Navigation/Plus.svg" />
                  </span>
                  {{ $t("mealsystem.suppliers.button.create") }}
                </span>
              </div>
          </div>
        </div>
      </div>
    </div>
    
    <!--begin::Card-->
    <div class="card card-custom" >
      <!--begin::Body-->
      <div class="card-body">
        <div class="table-responsive ">
          <table class="table table-head-custom table-head-bg table-vertical-center">
            <thead>
              <tr class="text-left text-uppercase">
                <th width="20"></th>
                <th>{{ $t("mealsystem.suppliers.label.name") }}</th>
                <th>{{ $t("mealsystem.suppliers.label.email") }}</th>
                <th>{{ $t("mealsystem.suppliers.label.phone") }}</th>
                <th width="20"></th>
              </tr>
            </thead>
            <tbody v-if="!loading">
                <tr v-for="(supplier, index) in suppliers.data" :key="index">
                  <td class="pl-0">
                    <a class="btn btn-link btn-sm btn btn-clean btn-hover-light-primary btn-sm btn-icon" v-b-tooltip.right="$t('button.settings')" v-b-modal.modal-supplier-edit @click="loadSupplier(supplier)"><i class="ki ki-gear icon-md"></i></a>
                  </td>
                  <td>
                    <span class="font-weight-bold">{{ supplier.name }}</span>
                  </td>
                  <td>
                    <span v-if="supplier.email">{{ supplier.email }}</span>
                    <span v-else class="text-muted">-</span>
                  </td>
                  <td>
                    <span v-if="supplier.phone">{{ supplier.phone }}</span>
                    <span v-else class="text-muted">-</span>
                  </td>
                  <td>
                    <a class="btn btn-link btn-sm btn btn-clean btn-hover-light-danger btn-sm btn-icon" v-b-tooltip.left="$t('mealsystem.suppliers.title.delete_supplier')" v-b-modal.modal-supplier-delete @click="setSupplier(supplier)"><i class="ki ki-bold-close icon-sm text-danger"></i></a>
                  </td>
                </tr>
            </tbody>
          </table>
    
        </div>
      </div>
      <!--end::Body-->
    </div>
    <!--end::Card-->

    <b-modal id="modal-supplier-delete" :okTitle="$t('button.delete')" okVariant="danger" :title="$t('mealsystem.suppliers.title.confirm_delete_supplier_title')">
      <span v-html="$t('mealsystem.suppliers.title.confirm_delete_supplier_text')"></span>
        <template #modal-footer="{ cancel }">
            <b-button variant="secondary" @click="cancel()" class="mr-3">
              {{ $t('button.cancel') }}
          </b-button> 
          <b-button variant="danger" @click="deleteSupplier(selectedSupplier)">
            {{ $t('mealsystem.suppliers.button.delete_supplier') }}
          </b-button>         
        </template>
    </b-modal>

  <b-modal id="modal-supplier-edit" size="lg" :okTitle="$t('button.save')" okVariant="success" :title="$t('mealsystem.suppliers.title.edit_supplier')">
      <ValidationObserver ref="form">

          <div class="d-flex">
                <div class="col-12">
                    <span class="mb-2 d-block">
                        <h3 class="font-size-lg font-weight-bolder mb-3 text-primary">
                            <span class="svg-icon svg-icon-md svg-icon-primary mr-1">
                                <inline-svg src="/media/svg/icons/Design/Edit.svg" />
                            </span>
                            {{ $t('mealsystem.suppliers.label.name') }}
                        </h3>    
                    </span>  
                    <div>
                        <ValidationProvider rules="required|min:3" v-slot="{ errors }">
                        <input 
                                name="supplier.name"
                                id="supplier.name"
                                type="text" 
                                class="form-control form-control-lg form-control-solid"
                                :placeholder="$t('mealsystem.suppliers.placeholder.name')"
                                v-model="selectedSupplier.name"
                        >                
                        <span class="text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                </div>
            </div>
          <hr />

          <div class="d-flex align-items-top">
              <div class="col-6 pr-7">
                
                  <div class="form-group row">
                    <label class="col-xl-2 col-lg-3 col-form-label text-right" for="supplier.in">{{ $t("mealsystem.suppliers.label.in") }}</label>
                    <div class="col-lg-5 col-xl-7">            
                        <ValidationProvider>
                        <input 
                            name="supplier.in"
                            id="supplier.in"
                            type="text" 
                            class="form-control form-control-lg form-control-solid"
                            :placeholder="$t('mealsystem.suppliers.placeholder.in')"
                            v-model="selectedSupplier.in"
                            >    
                        </ValidationProvider>    
                    </div>
                    <div class="col-lg-4 col-xl-3">
                        <button class="col-xl-12 py-3 btn btn-primary" @click.prevent="loadAresData" ref="load_ares">{{ $t("button.load") }}</button>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-xl-2 col-lg-3 col-form-label text-right" for="supplier.tin">{{ $t("mealsystem.suppliers.label.tin") }}</label>
                    <div class="col-lg-5 col-xl-10">            
                        <ValidationProvider>
                        <input 
                            name="supplier.tin"
                            id="supplier.tin"
                            type="text" 
                            class="form-control form-control-lg form-control-solid"
                            :placeholder="$t('mealsystem.suppliers.placeholder.tin')"
                            v-model="selectedSupplier.tin"
                            >    
                        </ValidationProvider>    
                    </div>
                </div>

                <hr class="mb-7" />

                <div class="form-group row">
                    <label class="col-xl-2 col-lg-3 col-form-label text-right" for="supplier.email">{{ $t("mealsystem.suppliers.label.email") }}</label>
                    <div class="col-lg-5 col-xl-10">            
                        <ValidationProvider>
                        <input 
                            name="supplier.email"
                            id="supplier.email"
                            type="text" 
                            class="form-control form-control-lg form-control-solid"
                            :placeholder="$t('mealsystem.suppliers.placeholder.email')"
                            v-model="selectedSupplier.email"
                            >    
                        </ValidationProvider>    
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-xl-2 col-lg-3 col-form-label text-right" for="supplier.phone">{{ $t("mealsystem.suppliers.label.phone") }}</label>
                    <div class="col-lg-5 col-xl-10">            
                        <ValidationProvider>
                        <input 
                            name="supplier.phone"
                            id="supplier.phone"
                            type="text" 
                            class="form-control form-control-lg form-control-solid"
                            :placeholder="$t('mealsystem.suppliers.placeholder.phone')"
                            v-model="selectedSupplier.phone"
                            >    
                        </ValidationProvider>    
                    </div>
                </div>

              </div>
              <div class="col-6 flex-column border-left d-flex pl-7">

                <div class="form-group row">
                    <label class="col-xl-2 col-lg-3 col-form-label text-right" for="supplier.street">{{ $t("mealsystem.suppliers.label.street") }}</label>
                    <div class="col-lg-5 col-xl-10">            
                        <ValidationProvider>
                        <input 
                            name="supplier.street"
                            id="supplier.street"
                            type="text" 
                            class="form-control form-control-lg form-control-solid"
                            :placeholder="$t('mealsystem.suppliers.placeholder.street')"
                            v-model="selectedSupplier.street"
                            >    
                        </ValidationProvider>    
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-xl-2 col-lg-3 col-form-label text-right" for="supplier.house_number">{{ $t("mealsystem.suppliers.label.house_number") }}</label>
                    <div class="col-lg-5 col-xl-10">            
                        <ValidationProvider>
                        <input 
                            name="supplier.house_number"
                            id="supplier.house_number"
                            type="text" 
                            class="form-control form-control-lg form-control-solid"
                            :placeholder="$t('mealsystem.suppliers.placeholder.house_number')"
                            v-model="selectedSupplier.house_number"
                            >    
                        </ValidationProvider>    
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-xl-2 col-lg-3 col-form-label text-right" for="supplier.city">{{ $t("mealsystem.suppliers.label.city") }}</label>
                    <div class="col-lg-5 col-xl-10">            
                        <ValidationProvider>
                        <input 
                            name="supplier.city"
                            id="supplier.city"
                            type="text" 
                            class="form-control form-control-lg form-control-solid"
                            :placeholder="$t('mealsystem.suppliers.placeholder.city')"
                            v-model="selectedSupplier.city"
                            >    
                        </ValidationProvider>    
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-xl-2 col-lg-3 col-form-label text-right" for="supplier.zip">{{ $t("mealsystem.suppliers.label.zip") }}</label>
                    <div class="col-lg-5 col-xl-10">            
                        <ValidationProvider>
                        <input 
                            name="supplier.zip"
                            id="supplier.zip"
                            type="text" 
                            class="form-control form-control-lg form-control-solid"
                            :placeholder="$t('mealsystem.suppliers.placeholder.zip')"
                            v-model="selectedSupplier.zip"
                            >    
                        </ValidationProvider>    
                    </div>
                </div>

              </div>
          </div>

      </ValidationObserver>
        <template #modal-footer="{ cancel }">
            <b-button variant="secondary" @click="cancel()" class="mr-3">
              {{ $t('button.cancel') }}
          </b-button> 
          <b-button variant="success" @click="updateSupplier(selectedSupplier)">
            {{ $t('mealsystem.suppliers.button.save_supplier') }}
          </b-button>         
        </template>
    </b-modal>

  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import { checkErrors } from "@/core/utils/api-errors";

export default {
  data() {
    return {
      loading: false,
      suppliers: null,
      selectedSupplier: {},
      page: 1,
      search: "",
    };
  },
  components: {

  },
  beforeMount() {
    this.getSuppliers();
  },
  metaInfo () { 
      return { title: this.$t("mealsystem.meta.suppliers_list")} 
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("mealsystem.meta.suppliers"), subtitle: this.$t("mealsystem.meta.suppliers_list") }]);
  },
  computed: {
  
  },
  methods: {
    getSuppliers() {
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiGet(
        "/meal-system/suppliers?search=" + this.search)
        .then((response) => {
          this.suppliers = response.data;
          this.loading = false;
        });
    },
    setSupplier(supplier){
    	this.selectedSupplier = supplier;
    },
    loadSupplier(supplier){
      ApiService.setHeader();
      ApiService.apiGet(
        "/meal-system/suppliers/" + supplier.supplier_id)
        .then((response) => {
          this.selectedSupplier = response.data.data;
        }).catch(error => {
            checkErrors(error);
      });
    },
    usetSupplier(){
      this.selectedSupplier = {
        name: ""
      }
    },
    deleteSupplier(supplier){
      this.loading = true;
      ApiService.setHeader();      
      ApiService.apiPost("/meal-system/suppliers/" + supplier.supplier_id + "/delete")
        .then(() => {
            this.loading = false;
            this.$bvModal.hide('modal-supplier-delete');
            this.getsuppliers();
        }).catch(error => {
            checkErrors(error);
      });
    },
    updateSupplier(supplier){
      this.loading = true;
      this.$refs.form.validate().then(success => {
        if (!success) {
          this.loading = false;
          this.$toasted.error("Zkontrolujte zadané údaje.");
          return;
        } 

        if(supplier.supplier_id){
          this.endpoint = "/meal-system/suppliers/" + supplier.supplier_id + "/update"
        } else {
          this.endpoint  = "/meal-system/suppliers";
        }

        ApiService.setHeader();      
        ApiService.apiPost(this.endpoint,{
          'name': supplier.name,
          'in': supplier.in,
          'tin': supplier.tin,
          'phone': supplier.phone,
          'email': supplier.email,
          'street': supplier.street,
          'house_number': supplier.house_number,
          'city': supplier.city,
          'zip': supplier.zip,
        })
          .then(() => {
              this.loading = false;
              this.$bvModal.hide('modal-supplier-edit');
              this.getSuppliers();
          }).catch(error => {
              checkErrors(error);
        });
      });
    },
    searchSuppliers(){
      this.page = 1;
      this.getSuppliers(); 
      this.supplierSearch = {
        search: this.search
      } 
    },
    removeFilters(){
      this.page = 1;
      this.search = "";
      this.supplierSearch = {
        search: this.search
      };
      this.getSuppliers(); 
    },
    loadAresData() {
      const submitButton = this.$refs["load_ares"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiGet("/ares/" + this.selectedSupplier.in)
        .then((response) => {
          this.selectedSupplier.name = response.data.ares.company;
          this.selectedSupplier.tin = response.data.ares.tin;
          this.selectedSupplier.street = response.data.ares.street;
          this.selectedSupplier.house_number = response.data.ares.house_number;
          this.selectedSupplier.city = response.data.ares.city;
          this.selectedSupplier.zip = response.data.ares.zip;
          this.loading = false;
          submitButton.classList.remove("spinner","spinner-light","spinner-right");
        })
        .catch(() => {
          this.$toasted.error('Nastala chyba.')
          this.loading = false;
          submitButton.classList.remove("spinner","spinner-light","spinner-right");
        })
    },
  }
};
</script>
